<template>
  <v-navigation-drawer id="core-navigation-drawer" style="background-color:rgb(71 130 180);" v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl" :src="barImage" mobile-breakpoint="960" app width="260" v-bind="$attrs">

    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider />
    <div style="text-align:left; height:80px;margin-top:32px;">
      <img style="margin-left:20%" src="../../../../assets/lg_grupo_w.png" width="40%">

    </div>

    <v-divider class="mb-2" />
    <!--
<v-img 
            class="c-sidebar-brand-full" 
            src="../../../../assets/logo_tamojunto.png"
            name="logo" 
            :height="160" 
            viewBox="0 0 506 134"/>
 -->
    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <v-divider class="mb-2" />



  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    promotores: [],
    user: "",

    items: [
      {
        title: 'Performance',
        roles: ['ROLE_RESUMO', 'ROLE_CAMPANHA', 'ROLE_CONTRATO', 'ROLE_OBITO', 'ROLE_SEPULTAMENTO'],

      },

      {
        icon: 'mdi-calculator',
        title: 'Resumo',
        roles: ['ROLE_RESUMO'],
        to: 'financeiro',
      },



      {
        icon: 'mdi-file-document-check-outline',
        title: 'Carteira',
        roles: ['ROLE_CONTRATO'],
        to: 'contrato',
      },

      {
        icon: 'mdi-coffin',
        title: 'Óbitos',
        roles: ['ROLE_OBITO'],
        to: 'obito',
      },



      {
        icon: 'mdi-flower',
        title: 'Sepultamento',
        roles: ['ROLE_SEPULTAMENTO'],
        to: 'sepultamento',
      },

      {
        icon: 'mdi-cash-clock',
        title: 'Inadimplência',
        roles: ['ROLE_INADIMPLENCIA_PERFORMANCE'],
        to: 'inadimplenciaPerformance',
      },

      {
        icon: 'mdi-handshake',
        title: 'Acordos',
        roles: ['ROLE_ACORDO_PERFORMANCE'],
        to: 'acordosPerformance',
      },

      {
        icon: 'mdi-alert',
        title: 'Sinistralidade',
        roles: ['ROLE_SINISTRALIDADE'],
        to: 'sinistralidade',
      },

      {
        icon: 'mdi-calculator',
        title: 'Contas',
        roles: ['ROLE_CONTAS'],
        to: 'contas',
      },

      {
        title: '',
      },

      {
        title: 'Acompanhamento',
        roles: ['ROLE_AUDITORIA', 'ROLE_COBRANCA', 'ROLE_FATURAMENTO', 'ROLE_ACOMPANHAMENTO_VENDA'],

      },



      {
        icon: 'mdi-marker',
        title: 'Auditoria',
        roles: ['ROLE_AUDITORIA'],
        to: 'auditoria',
      },


      {
        icon: 'mdi-cash-refund',
        title: 'Cobrança',
        roles: ['ROLE_REMESSA_NEGATIVADO', 'ROLE_COBRANCA', 'ROLE_NEGATIVADO'],
        to: 'cobranca',
        group: '',
        children: [

          {
            icon: 'mdi-cash-refund',
            title: 'Acordos',
            // roles: [ 'ROLE_COBRANCA'],
            to: 'cobranca',
          },

          {
            title: 'Negativados',
            //  roles: [ 'ROLE_NEGATIVADO'],
            to: 'negativados',
          },

          {
            title: 'Remessa Serasa',
            // roles: [ 'ROLE_REMESSA_NEGATIVADO'],
            to: 'remessa',
          },

        ]
      },







      {
        icon: 'mdi-finance',
        title: 'Faturamento',
        roles: ['ROLE_FATURAMENTO'],
        to: 'faturamento',
      },

      {
        icon: 'mdi-toolbox-outline',
        title: 'Serviços Funerários',
        roles: ['ROLE_COB_FUNERARIA'],
        to: '',
      },

      {
        icon: 'mdi-face-agent',
        title: 'Relacionamento',
        roles: ['ROLE_COB_RELACIONAMENTO'],
        to: '',
      },

      {
        icon: 'mdi-grave-stone',
        title: 'Sepultamento',
        roles: ['ROLE_COB_SEPULTAMENTO'],
        to: '',
      },

      {
        icon: 'mdi-sale',
        title: 'Vendas',
        roles: ['ROLE_ACOMPANHAMENTO_VENDA'],
        to: 'acompanhamentovenda',
      },

      {
        icon: 'mdi-toolbox-outline',
        title: 'Funerária',
        roles: ['ROLE_FUNERARIA'],
        to: 'funeraria',
      },

      {
        icon: 'mdi-store',
        title: 'Loja',
        roles: ['ROLE_LOJA'],
        to: 'loja',
      },

      {
        icon: 'mdi-safe',
        title: 'Retenção de Vendas',
        roles: ['ROLE_RETENCAO'],
        to: 'retencao',
      },

      {
        title: '',
      },


      {
        title: 'Comissão',
        roles: ['ROLE_COMISSAO', 'ROLE_COMISSAO_VENDA', 'ROLE_COMISSAO_FUNERARIA', 'ROLE_COMISSAO_ENTREGAS'],

      },

      {
        icon: 'mdi-percent',
        title: 'Acordos',
        roles: ['ROLE_COMISSAO'],
        to: 'comissao',
      },



      {
        icon: 'mdi-percent',
        title: 'Vendas',
        roles: ['ROLE_COMISSAO_VENDA'],
        to: 'comissaoVenda',
      },

      {
        icon: 'mdi-percent',
        title: 'Funerária',
        roles: ['ROLE_COMISSAO_FUNERARIA'],
        to: 'comissaoFuneraria',
      },

      {
        icon: 'mdi-percent',
        title: 'Entregas',
        roles: ['ROLE_COMISSAO_ENTREGAS'],
        to: 'comissaoEntrega',
      },

      {
        icon: 'mdi-link-variant',
        title: 'Vínculo',
        roles: ['ROLE_COMISSAO_CONTROLE'],
        to: 'comissaoControle',
      },

      {
        icon: 'mdi-alert-octagon',
        title: 'Ocorrência',
        roles: ['ROLE_COMISSAO_CONTROLE'],
        to: 'comissaoConformidade',
      },

      {
        title: '',
      },

      {
        title: 'Colaboradores',
        roles: ['ROLE_COMISSAO_ALELO'],

      },

      {
        icon: 'mdi-gift',
        title: 'Comissões Alelo',
        roles: ['ROLE_COMISSAO_ALELO'],
        to: 'comissaoAlelo',
      },

      {
        title: '',
      },

      {
        title: 'Bitrix',
        roles: ['ROLE_BITRIX'],

      },

      {
        icon: 'mdi-account-multiple',
        title: 'Leads',
        roles: ['ROLE_BITRIX'],
        to: 'bitrixLead',
      },


      {
        title: '',
      },



      {
        title: 'Consulta Genérica',
        roles: ['ROLE_RECEITA', 'ROLE_INADIMPLENCIA', 'ROLE_VENDA', 'ROLE_CANCELAMENTO', 'ROLE_RENEGOCIACAO'],

      },

      {
        icon: 'mdi-cash',
        title: 'Receita',
        roles: ['ROLE_RECEITA'],
        to: 'receita',
      },

      {
        icon: 'mdi-cash-clock',
        title: 'Inadimplência',
        roles: ['ROLE_INADIMPLENCIA'],
        to: 'inadimplencia',
      },

      {
        icon: 'mdi-sale',
        title: 'Vendas',
        roles: ['ROLE_VENDA'],
        to: 'venda',
      },

      {
        icon: 'mdi-sale',
        title: 'Entre Empresas',
        roles: ['ROLE_VENDAS_EMPRESA'],
        to: 'vendasEmpresas',
      },

      {
        icon: 'mdi-delete',
        title: 'Cancelamentos',
        roles: ['ROLE_CANCELAMENTO'],
        to: 'cancelamento',
      },

      {
        icon: 'mdi-handshake',
        title: 'Renegociação',
        roles: ['ROLE_RENEGOCIACAO'],
        to: 'acordos',
      },

      {
        icon: 'mdi-text-box-search-outline',
        title: 'Documentos',
        roles: ['ROLE_MANUTENCAO_DOC'],
        to: 'documentos',
      },

      {
        title: '',
      },

      {
        title: 'Stratws',
        roles: ['ROLE_STRATUS'],
      },
      {
        icon: 'mdi-finance',
        title: 'Indicadores',
        roles: ['ROLE_STRATUS'],
        to: 'indicadores',
      },
      {
        title: '',
      },
      {
        title: 'Mensageria',
        roles: ['ROLE_MENSAGERIA'],
      },
      {
        icon: 'mdi-currency-usd-off',
        title: 'Cobrança',
        roles: ['ROLE_MENSAGERIA'],
        to: 'mensageria-cobranca',
      },
      {
        icon: 'mdi-calendar-blank',
        title: 'Lembrete',
        roles: ['ROLE_MENSAGERIA'],
        to: 'mensageria-lembrete',
      },
      {
        icon: 'mdi-magnify',
        title: 'Consulta',
        roles: ['ROLE_MENSAGERIA'],
        to: 'mensageria-consulta',
      },
      


      {
        title: '',
      },



      {
        title: 'Financeiro',
        roles: ['ROLE_IMPORTACAO_RADAR', 'ROLE_IMPORTACAO_RADAR_APP', 'ROLE_FLUXO'], 
      },

      {
        icon: 'mdi-finance',
        title: 'Fluxo',
        roles: ['ROLE_FLUXO'],
        to: 'fluxo',
      },

      {
        icon: 'mdi-cash-clock',
        title: 'Radar Documentos',
        roles: ['ROLE_IMPORTACAO_RADAR'],
        to: 'importacao',
      },

      {
        icon: 'mdi-cash-clock',
        title: 'Importação Radar',
        roles: ['ROLE_IMPORTACAO_RADAR_APP'],
        to: 'importacaoapp',
      },

      {
        icon: 'mdi-cash-clock',
        title: 'Conciliação',
        roles: ['ROLE_CONCILIACAO'],
        to: 'conciliacao',
      },

      {
        icon: 'mdi-text-box-search-outline',
        title: 'Documentos',
        roles: ['ROLE_DOCUEMNTOS_IVERTEX'],
        to: 'documentos',
      },
      {
        title: '',
      },

      {
        title: 'Configurações',

      },



      {
        icon: 'mdi-key',
        title: 'Alterar Senha',
        to: 'register',
      },
      {
        title: '',
      },
      {
        title: 'Administrador',
        roles: ['ROLE_NOTIFICACAO', 'ROLE_LOG', 'ROLE_USER'],

      },



      {
        icon: 'mdi-bell',
        title: 'Notificação',
        roles: ['ROLE_NOTIFICACAO'],
        to: 'notificacao',
      },

      {
        icon: 'mdi-history',
        title: 'LOG',
        roles: ['ROLE_LOG'],
        to: 'historico',
      },


      {
        icon: 'mdi-account',
        title: 'Usuário',
        roles: ['ROLE_USER'],
        to: 'usuario',
      },


      {
        icon: 'mdi-logout',
        title: 'Sair',
        to: 'login'
      },
    ],
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        //  return true;
        //alert('.....'+this.$store.state.drawer)
        return this.$store.state.drawer
      },
      set(val) {
        //alert(val)
        this.$store.commit('SET_DRAWER', val)
      }
    },

    descobrirUser() {
      this.user = JSON.parse(sessionStorage.getItem('user'))

    },


    computedItems() {
      // this.ranking()

      return this.items.map(this.mapItem).filter((item) => item !== null);
    },

    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },



  methods: {

    mapItem(item) {

      if (this.user == "") {
        this.user = JSON.parse(sessionStorage.getItem('user'))
      }

      const hasPermission = this.checkPermission(item);

      return hasPermission
        ? {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
        : null;
    },


    checkPermission(item) {
      // Verifica se o usuário está definido e se tem a permissão especificada em 'role' ou 'ROLE_ADMIN'
      return (
        this.user &&
        this.user.roles &&
        Array.isArray(this.user.roles) && // Certifica-se de que this.user.roles é um array
        (item.roles ? item.roles.some(role => this.user.roles.includes(role)) : true) ||
        this.user.roles.includes('ROLE_ADMIN')
      );

    },


    ranking() {

    },

    logout() {
      sessionStorage.removeItem('user')
      to: login;
    },


  },
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
